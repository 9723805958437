
import {
  getInstance as getPedidoService,
  Pedido,
} from "@/services/PedidoService";
import {
  TipoPagamento,
  getTiposPagamentos,
} from "@/services/TipoPagamentoService";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Pix from "./Pix.vue";
import PagSaldo from "./PagSaldo.vue";
import PagCard from "./PagCartao.vue";

export default defineComponent({
  components: { Pix, PagCard, PagSaldo },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const tiposPagamentos = ref<TipoPagamento[]>([]);
    const loadingTiposPagamentos = ref(false);
    async function loadTiposPagamentos() {
      loadingTiposPagamentos.value = true;
      try {
        tiposPagamentos.value = await getTiposPagamentos();
      } finally {
        loadingTiposPagamentos.value = false;
      }
    }

    const pedido = ref<Pedido>();
    const loading = ref(false);
    async function loadPedido() {
      loading.value = true;
      try {
        pedido.value = await getPedidoService().getById(
          route.params.id as unknown as number
        );
        if (pedido.value.status === "pendente") {
          loadTiposPagamentos();
        }
      } finally {
        loading.value = false;
      }
    }
    onMounted(loadPedido);

    const dialogPix = ref(false);
    const dialogCard = ref(false);
    const dialogSaldo = ref(false);

    function pedidoPago(status = "pago") {
      dialogPix.value = false;
      dialogCard.value = false;
      dialogSaldo.value = false;
      pedido.value && (pedido.value.status = status);
    }

    const canPagSaldo = computed(
      () => pedido.value?.tipo !== "primeiro_pedido"
    );
    const isCompraFromPa = computed(
      () =>
        pedido.value?.pontoApoio &&
        !["pa", "pa_ativacao"].includes(pedido.value?.tipo || "")
    );

    return {
      loading,
      pedido,
      voltar() {
        router.push({ name: "loja" });
      },

      loadingTiposPagamentos,
      tiposPagamentos,
      dialogPix,
      dialogCard,
      dialogSaldo,
      pedidoPago,
      canPagSaldo,
      isCompraFromPa,
    };
  },
});
