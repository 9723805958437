import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { CrudService, PageConsult, PageConsultInput } from "./Services";
import { baseURL } from "@/api/rest";
import { clone, filterKeys } from "@/utils/object";

export interface TipoPagamento {
  id: number;
  descricao: string;
  createdAt?: Date;
  updatedAt?: Date;
  status: string;
  gateway?: string;
  config?: any;
  tipo: string;
}

export async function getTiposPagamentos(): Promise<TipoPagamento[]> {
  const result = await client
    .query({
      query: gql`
        query {
          tipoPagamentos(somenteAtivos: true first: 10) {
            data {
              id
              descricao
              tipo
            }
          }
        }
      `,
    });
  const { data: { tipoPagamentos: { data } } } = result;
  return data;
}
