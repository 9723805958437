<template>
  <p-dialog
    header="Pagamento com Saldo"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 400px"
  >
    <div v-if="msg">
      <p-message severity="warn" :key="msgKey">
        {{ msg }}
      </p-message>
    </div>

    <div class="p-text-center">
      <p-progress-spinner v-if="loadingSaldo" />
    </div>

    <div v-if="!loadingSaldo && saldo < totalPagar">
      <div class="p-mb-4">
        Seu Saldo Disponível: <b>{{ $formatCurrency(saldo) }}</b>
      </div>
      <div style="color: red" class="p-text-center">
        Você não possuí saldo suficiente para fazer o pagamento deste pedido no
        valor de
        <b>{{ $formatCurrency(totalPagar) }}</b>
      </div>
      <div class="p-d-flex p-flex-row-reverse p-jc-start p-mt-4">
        <p-button label="OK" class="p-button-secondary p-mr-2" @click="close" />
      </div>
    </div>

    <form
      class="form"
      @submit.prevent="pagar"
      v-if="!loadingSaldo && saldo >= totalPagar"
    >
      <div class="p-fluid">
        <div class="p-field">
          <label style="width: 100%">
            Seu Saldo Disponível: <b>{{ $formatCurrency(saldo) }}</b></label
          >
        </div>
        <div class="p-field">
          <label style="width: 100%">
            Informe Sua Senha Financeira:
            <span style="float: right">
              <a href="#" @click.prevent="esqueceuSenha">Esqueci minha senha</a>
              <p-progress-spinner
                v-if="gerandoSenha"
                style="width: 15px; height: 15px"
              />
            </span>
          </label>
          <p-inputtext
            required
            type="password"
            v-model="form.senhaFinanceira"
          />
        </div>
        <div class="p-field">
          <label style="width: 100%">
            Para sua segurança, vamos enviar um código no seu e-mail:
          </label>
          <p-button
            :loading="sendingCode"
            @click="sendCode"
            label="Clique Aqui Para Enviar o Código Agora"
            :disabled="!canSendCodigo"
          />
        </div>
        <div class="p-field">
          <label style="width: 100%">
            Informe o código recebido em seu e-mail:
          </label>
          <p-inputtext v-model="form.codigo" required />
        </div>
      </div>

      <div class="p-d-flex p-flex-row-reverse p-jc-start">
        <p-button
          label="Pagar"
          :loading="saving"
          type="submit"
          :disabled="!canPagar"
        />
        <p-button
          label="Cancelar"
          class="p-button-secondary p-mr-2"
          @click="close"
        />
      </div>
    </form>
  </p-dialog>
</template>

<script lang="ts">
import { Pedido } from "@/services/PedidoService";
import { TipoPagamento } from "@/services/TipoPagamentoService";
import { useToast } from "primevue/usetoast";
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";
import { formatNumber } from "@/utils/format";
import { useStore } from "@/store";
import { getSaldos } from "@/services/DashboardService";
import { useSenhaFinanceira } from "../ChangePasswordFinanceira.vue";
import { http } from "@/api/rest";
import { clone } from "@/utils/object";
import { client } from "@/api/apollo";
import gql from "graphql-tag";

interface PedidoPagamentoResponse {
  type: "sucess" | "error";
  mensagem: string;
}

async function pagar(
  pedidoId: number,
  tipoPagamentoId: number,
  extra: any
): Promise<PedidoPagamentoResponse> {
  const result = await client.query({
    variables: {
      pedidoId,
      tipoPagamentoId,
      extra: JSON.stringify(extra),
    },
    query: gql`
      query ($pedidoId: ID!, $tipoPagamentoId: ID!, $extra: Json) {
        pagarPedido(
          pedidoId: $pedidoId
          tipoPagamentoId: $tipoPagamentoId
          extra: $extra
        ) {
          type
          mensagem
        }
      }
    `,
  });
  const {
    data: { pagarPedido },
  } = result;
  return pagarPedido;
}

export default defineComponent({
  emits: ["update:visible", "pagou"],
  props: {
    pedido: {
      type: Object as PropType<Pedido>,
      required: true,
    },
    tipoPagamento: {
      type: Object as PropType<TipoPagamento>,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const msg = ref<string>("");
    const msgKey = ref<number>(0);

    const saving = ref(false);

    watch(
      () => props.visible,
      (v) => {
        if (!v) {
          form.senhaFinanceira = "";
          form.codigo = "";
          msg.value = "";
        } else {
          loadSaldo();
        }
      }
    );

    const toast = useToast();

    function close() {
      if (!saving.value) {
        emit("update:visible", false);
      }
    }

    const saldo = ref(0);
    const loadingSaldo = ref(true);
    async function loadSaldo() {
      loadingSaldo.value = true;
      try {
        const saldos = await getSaldos();
        saldo.value = parseFloat(saldos.disponivel || "0");
      } finally {
        loadingSaldo.value = false;
      }
    }
    onMounted(loadSaldo);

    const {
      gerando: gerandoSenha,
      gerado,
      esqueceuSenha,
    } = useSenhaFinanceira();

    const form = reactive({
      senhaFinanceira: "",
      codigo: "",
    });

    const sendingCode = ref(false);
    async function sendCode() {
      sendingCode.value = true;
      try {
        await http.post("api/users/check-pagamento", {
          senha_financeira: form.senhaFinanceira,
        });
        toast.add({
          severity: "success",
          summary: "Código enviado",
          life: 3000,
        });
      } finally {
        sendingCode.value = false;
      }
    }
    const canSendCodigo = computed(() => {
      return !!form.senhaFinanceira;
    });

    const canPagar = computed(() => {
      return form.senhaFinanceira && form.codigo;
    });

    const totalPagar = computed(() => {
      return props.pedido.taxaAdesao || props.pedido.total;
    });

    return {
      totalPagar,
      msg,
      msgKey,
      close,
      saving,
      saldo,
      loadingSaldo,
      gerandoSenha,
      esqueceuSenha,
      canSendCodigo,
      sendingCode,
      sendCode,
      form,
      canPagar,
      async pagar() {
        msg.value = "";
        saving.value = true;
        try {
          const resp = await pagar(
            props.pedido.id,
            props.tipoPagamento.id,
            clone(form)
          );
          if (resp.type === "error") {
            msgKey.value++;
            msg.value = resp.mensagem;
            return;
          }
          emit("pagou");
        } catch (e) {
          console.log(e);
        } finally {
          saving.value = false;
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && saving.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.p-inputtext) {
  font-size: 1.3em;
}
</style>