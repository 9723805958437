
import { Pedido } from "@/services/PedidoService";
import { TipoPagamento } from "@/services/TipoPagamentoService";
import { useToast } from "primevue/usetoast";
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from "vue";
import { formatNumber } from "@/utils/format";
import { useStore } from "@/store";
import { getSaldos } from "@/services/DashboardService";
import { useSenhaFinanceira } from "../ChangePasswordFinanceira.vue";
import { http } from "@/api/rest";
import { clone } from "@/utils/object";
import { client } from "@/api/apollo";
import gql from "graphql-tag";

interface PedidoPagamentoResponse {
  type: "sucess" | "error";
  mensagem: string;
}

async function pagar(
  pedidoId: number,
  tipoPagamentoId: number,
  extra: any
): Promise<PedidoPagamentoResponse> {
  const result = await client.query({
    variables: {
      pedidoId,
      tipoPagamentoId,
      extra: JSON.stringify(extra),
    },
    query: gql`
      query ($pedidoId: ID!, $tipoPagamentoId: ID!, $extra: Json) {
        pagarPedido(
          pedidoId: $pedidoId
          tipoPagamentoId: $tipoPagamentoId
          extra: $extra
        ) {
          type
          mensagem
        }
      }
    `,
  });
  const {
    data: { pagarPedido },
  } = result;
  return pagarPedido;
}

export default defineComponent({
  emits: ["update:visible", "pagou"],
  props: {
    pedido: {
      type: Object as PropType<Pedido>,
      required: true,
    },
    tipoPagamento: {
      type: Object as PropType<TipoPagamento>,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const msg = ref<string>("");
    const msgKey = ref<number>(0);

    const saving = ref(false);

    watch(
      () => props.visible,
      (v) => {
        if (!v) {
          form.senhaFinanceira = "";
          form.codigo = "";
          msg.value = "";
        } else {
          loadSaldo();
        }
      }
    );

    const toast = useToast();

    function close() {
      if (!saving.value) {
        emit("update:visible", false);
      }
    }

    const saldo = ref(0);
    const loadingSaldo = ref(true);
    async function loadSaldo() {
      loadingSaldo.value = true;
      try {
        const saldos = await getSaldos();
        saldo.value = parseFloat(saldos.disponivel || "0");
      } finally {
        loadingSaldo.value = false;
      }
    }
    onMounted(loadSaldo);

    const {
      gerando: gerandoSenha,
      gerado,
      esqueceuSenha,
    } = useSenhaFinanceira();

    const form = reactive({
      senhaFinanceira: "",
      codigo: "",
    });

    const sendingCode = ref(false);
    async function sendCode() {
      sendingCode.value = true;
      try {
        await http.post("api/users/check-pagamento", {
          senha_financeira: form.senhaFinanceira,
        });
        toast.add({
          severity: "success",
          summary: "Código enviado",
          life: 3000,
        });
      } finally {
        sendingCode.value = false;
      }
    }
    const canSendCodigo = computed(() => {
      return !!form.senhaFinanceira;
    });

    const canPagar = computed(() => {
      return form.senhaFinanceira && form.codigo;
    });

    const totalPagar = computed(() => {
      return props.pedido.taxaAdesao || props.pedido.total;
    });

    return {
      totalPagar,
      msg,
      msgKey,
      close,
      saving,
      saldo,
      loadingSaldo,
      gerandoSenha,
      esqueceuSenha,
      canSendCodigo,
      sendingCode,
      sendCode,
      form,
      canPagar,
      async pagar() {
        msg.value = "";
        saving.value = true;
        try {
          const resp = await pagar(
            props.pedido.id,
            props.tipoPagamento.id,
            clone(form)
          );
          if (resp.type === "error") {
            msgKey.value++;
            msg.value = resp.mensagem;
            return;
          }
          emit("pagou");
        } catch (e) {
          console.log(e);
        } finally {
          saving.value = false;
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && saving.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
