
import { client } from "@/api/apollo";
import { Pedido } from "@/services/PedidoService";
import { TipoPagamento } from "@/services/TipoPagamentoService";
import gql from "graphql-tag";
import { useToast } from "primevue/usetoast";
import { defineComponent, PropType, ref, watch } from "vue";
import useClipboard from "vue-clipboard3";
// @ts-ignore
import VueQrcode from "vue3-qrcode";

interface PedidoPagamentoResponse {
  type: "sucess" | "error";
  mensagem: string;
  pix: PixResponse;
}

interface PixResponse {
  txid: string;
  status: string;
  request: any;
  response: any;
  qrcode: string;
}

async function gerarPix(
  pedidoId: number,
  tipoPagamentoId: number
): Promise<PedidoPagamentoResponse> {
  const result = await client.query({
    variables: { pedidoId, tipoPagamentoId },
    query: gql`
      query($pedidoId: ID!, $tipoPagamentoId: ID!) {
        pagarPedido(pedidoId: $pedidoId, tipoPagamentoId: $tipoPagamentoId) {
          type
          mensagem
          pix
        }
      }
    `,
  });
  const {
    data: { pagarPedido },
  } = result;
  pagarPedido.pix = JSON.parse(pagarPedido.pix);
  return pagarPedido;
}

async function consultarPix(
  pedidoId: number,
  tipoPagamentoId: number
): Promise<PixResponse> {
  const result = await client.query({
    variables: { pedidoId, tipoPagamentoId },
    query: gql`
      query($pedidoId: ID!, $tipoPagamentoId: ID!) {
        consultarPix(pedidoId: $pedidoId, tipoPagamentoId: $tipoPagamentoId)
      }
    `,
  });
  const {
    data: { consultarPix },
  } = result;
  return JSON.parse(consultarPix);
}

export default defineComponent({
  emits: ["update:visible", "pagou"],
  components: {
    VueQrcode,
  },
  props: {
    pedido: {
      type: Object as PropType<Pedido>,
      required: true,
    },
    tipoPagamento: {
      type: Object as PropType<TipoPagamento>,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const msg = ref<string>("");
    const msgKey = ref<number>(0);

    const pix = ref<PixResponse>();
    const loading = ref(false);
    async function loadPix() {
      loading.value = true;
      try {
        const resp = await gerarPix(props.pedido.id, props.tipoPagamento.id);
        if (resp.type === "error") {
          msgKey.value++;
          msg.value = resp.mensagem;
          return;
        }
        pix.value = resp.pix;
        if (pix.value.status === "concluido") {
          emit("pagou");
        }
      } finally {
        loading.value = false;
      }
    }

    const validando = ref(false);
    async function validar(timer = false) {
      if (!pix.value) {
        return;
      }
      validando.value = true;
      try {
        const pixResponse = await consultarPix(
          props.pedido.id,
          props.tipoPagamento.id
        );
        if (pixResponse?.status === "CONCLUIDA") {
          emit("pagou");
        } else {
          !timer &&
            toast.add({
              severity: "warn",
              summary: "Pix ainda não foi pago",
              life: 2_000,
            });
        }
      } finally {
        validando.value = false;
      }
    }

    const countTimer = ref(0);
    watch(
      () => props.visible,
      (v) => {
        countTimer.value = 0;
        pix.value = undefined;
        if (v) {
          loadPix();
          timer = setTimeout(autoVerificar, 30_000);
        } else {
          timer && clearTimeout(timer);
        }
      }
    );

    function close() {
      if (!loading.value) {
        emit("update:visible", false);
      }
    }

    const { toClipboard } = useClipboard();
    const toast = useToast();

    let timer: number;
    async function autoVerificar() {
      countTimer.value++;
      if (countTimer.value > 3) {
        return;
      }
      await validar(true);
      timer = setTimeout(autoVerificar, 15_000);
    }

    return {
      msg,
      msgKey,
      close,
      pix,
      loading,

      updateDialogVisible(v: boolean) {
        if (!v && loading.value) {
          return;
        }
        emit("update:visible", v);
      },

      validando,
      validar,

      copy() {
        if (pix.value) {
          toClipboard(pix.value?.qrcode);
          toast.add({
            severity: "success",
            summary: "Pix copiado",
            life: 3_000,
          });
        }
      },
    };
  },
});
