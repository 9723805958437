<template>
  <p-dialog
    header="Informe os Dados do Cartão"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 400px"
  >
    <div v-if="msg">
      <p-message severity="warn" :key="msgKey">{{ msg }}</p-message>
    </div>

    <form class="form" @submit.prevent="pagar" id="cc-form">
      <div class="p-fluid">
        <div class="p-field">
          <label>Nome impresso no cartão:</label>
          <p-inputtext
            v-model="nome"
            required
            autocomplete="cc-name"
            style="text-transform: uppercase"
          />
        </div>
        <div class="p-field">
          <label>Cpf do dono do cartão:</label>
          <p-inputtext
            v-model="documento"
            v-input-mask="['999.999.999-99']"
            required
          />
        </div>
        <div class="p-field" v-if="infoExtra">
          <label style="width: 100%">Data Nascimento</label>
          <x-input-date v-model="dataNascimento" required style="width: 120px" />
        </div>
        <div class="p-field" v-if="infoExtra">
          <label>Telefone</label>
          <InputMask v-model="telefone" mask="(99) 9999-9999" required />
        </div>
        <div class="p-field">
          <label>Número do cartão:</label>
          <InputMask
            autocomplete="cc-number"
            required
            v-model="card.number"
            mask="9999 9999 9999 9999"
            slotChar
            style="text-align: center"
          />
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col">
          <label>Vencimento:</label>
          <InputMask
            autocomplete="cc-exp"
            required
            v-model="exp"
            mask="99/99"
            slotChar
            style="width: 80px; text-align: center"
            maxlength="5"
            pattern="\d{2}\/\d{2}"
          />
        </div>
        <div class="p-field p-col">
          <label>CVC:</label>
          <p-inputtext
            v-model="card.cvv"
            required
            maxlength="3"
            @focus="isTypingCvc = true"
            @blur="isTypingCvc = false"
            style="width: 80px; text-align: center"
          />
        </div>
      </div>

      <div class="p-fluid" v-if="installments.length">
        <div class="p-field">
          <label>Pagamento:</label>
          <p-dropdown
            v-model="opcao"
            required
            :options="installments"
            optionLabel="currency"
            data-key="installment"
            style="min-width: 200px"
            class="p-mr-2 p-mb-2"
          >
            <template #value="{ value }">{{ formatInstallment(value) }}</template>
            <template #option="{ option }">{{ formatInstallment(option) }}</template>
          </p-dropdown>
        </div>
        <h5>
          <b>
            Total à pagar:
            {{ $formatCurrency((opcao.installment * opcao.value) / 100) }}
          </b>
        </h5>
      </div>
      <div v-if="installmentLoading" class="p-text-center">
        <p-progress-spinner
          v-if="installmentLoading"
          style="width: 15px; height: 15px"
        />
      </div>

      <div class="p-d-flex p-flex-row-reverse p-jc-start p-mt-2">
        <p-button
          label="Pagar"
          :icon="saving ? 'pi pi-spinner pi-spin' : ''"
          :disabled="saving || !opcao"
          type="submit"
        />
        <p-button
          label="Cancelar"
          class="p-button-secondary p-mr-2"
          @click="close"
        />
      </div>
    </form>
  </p-dialog>
</template>

<script lang="ts">
import { client } from "@/api/apollo";
import { Pedido } from "@/services/PedidoService";
import { TipoPagamento } from "@/services/TipoPagamentoService";
import { clone } from "@/utils/object";
import gql from "graphql-tag";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, nextTick, PropType, ref, watch } from "vue";
import { detectCardType } from "@/utils/card";
import { getInstallments, getPaymentToken } from "@/gerencianet";
import { formatNumber } from "@/utils/format";
import { useStore } from "@/store";

interface Cartao {
  brand: string; // 'visa', // bandeira do cartão
  number: string; //'4012001038443335', // número do cartão
  cvv: string; //'123', // código de segurança
  expiration_month: string; //'05', // mês de vencimento
  expiration_year: string; //'2021' // ano de ve
}

interface Installment {
  currency: string; // "75,00"
  has_interest: boolean;
  installment: 1;
  interest_percentage: number; // 0
  value: number;
}

const initialCartao: Cartao = {
  brand: "",
  number: "",
  cvv: "",
  expiration_month: "",
  expiration_year: "",
};

interface PedidoPagamentoResponse {
  type: "sucess" | "error";
  mensagem: string;
}

async function pagar(
  pedidoId: number,
  tipoPagamentoId: number,
  paymentToken: string,
  cardInfo: any
): Promise<PedidoPagamentoResponse> {
  const result = await client.query({
    variables: {
      pedidoId,
      tipoPagamentoId,
      paymentToken,
      cardInfo: JSON.stringify(cardInfo),
    },
    query: gql`
      query (
        $pedidoId: ID!
        $tipoPagamentoId: ID!
        $paymentToken: String
        $cardInfo: Json
      ) {
        pagarPedido(
          pedidoId: $pedidoId
          tipoPagamentoId: $tipoPagamentoId
          paymentToken: $paymentToken
          cardInfo: $cardInfo
        ) {
          type
          mensagem
        }
      }
    `,
  });
  const {
    data: { pagarPedido },
  } = result;
  return pagarPedido;
}

async function requestPaymentToken(card: Cartao) {
  const {
    data: { payment_token },
  } = await getPaymentToken(card);
  return payment_token as string;
}

export default defineComponent({
  emits: ["update:visible", "pagou"],
  props: {
    pedido: {
      type: Object as PropType<Pedido>,
      required: true,
    },
    tipoPagamento: {
      type: Object as PropType<TipoPagamento>,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isTypingCvc = ref(false);
    const exp = ref("");
    const nome = ref("");
    const documento = ref("");
    const saving = ref(false);
    const card = ref<Cartao>(clone(initialCartao));
    const telefone = ref("");
    const dataNascimento = ref("");
    const infoExtra = computed(() => {
      return (
        documento.value.replace(/\D/g, "").length >= 11 &&
        documento.value !== store.state.user?.pessoa.documento
      );
    });

    const installmentLoading = ref(false);
    const installments = ref<Installment[]>([]);
    const opcao = ref<Installment>(null!);

    const msg = ref<string>("");
    const msgKey = ref<number>(0);

    const totalPagar = computed(() => {
      return props.pedido.taxaAdesao || props.pedido.total;
    });

    watch(
      () => props.visible,
      (v) => {
        if (!v) {
          installments.value = [];
          opcao.value = null!;
          card.value = clone(initialCartao);
          exp.value = "";
        }
      }
    );

    const brand = computed(() => {
      return detectCardType(card.value.number.replace(/\D/g, "")) || "";
    });
    watch(brand, async (b) => {
      opcao.value = null!;
      installments.value = [];
      if (b) {
        installmentLoading.value = true;
        try {
          installments.value = (
            await getInstallments(totalPagar.value, brand.value)
          ).installments;
          opcao.value = installments.value[0];
        } catch (e: any) {
          console.log(e);
          msgKey.value++;
          msg.value = `Falha ao recuperar as opçoes de pagamento.${e.error_description || ""
            }`;
          return;
        } finally {
          installmentLoading.value = false;
        }
      }
    });

    const toast = useToast();

    function close() {
      if (!saving.value) {
        emit("update:visible", false);
      }
    }

    return {
      installmentLoading,
      opcao,
      installments,
      msg,
      msgKey,
      close,
      exp,
      isTypingCvc,
      nome,
      saving,
      documento,
      card,
      telefone,
      dataNascimento,
      infoExtra,
      async pagar() {
        msg.value = "";
        saving.value = true;
        try {
          const cc = clone(card.value);
          cc.number = cc.number.replace(/\D/g, "");
          cc.brand = detectCardType(cc.number) || "";
          const [m, y] = exp.value.split("/");
          cc.expiration_month = m;
          cc.expiration_year = `20${y}`;

          let paymentToken = "";
          try {
            paymentToken = await requestPaymentToken(cc);
          } catch (e) {
            console.log(e);
            msgKey.value++;
            msg.value = "Cartão inválido";
            return;
          }

          const resp = await pagar(
            props.pedido.id,
            props.tipoPagamento.id,
            paymentToken,
            {
              nome: nome.value,
              documento: documento.value,
              telefone: telefone.value,
              dataNascimento: dataNascimento.value,
              installment: clone(opcao.value),
            }
          );
          if (resp.type === "error") {
            msgKey.value++;
            msg.value = resp.mensagem;
            return;
          }
          emit("pagou");
        } catch (e) {
          console.log(e);
        } finally {
          saving.value = false;
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && saving.value) {
          return;
        }
        emit("update:visible", v);
      },

      formatInstallment(p: Installment) {
        if (!p) {
          return;
        }
        if (p.installment === 1) {
          return `1x de R$ ${p.currency} (sem juros)`;
        }
        let vJuros = formatNumber(p.interest_percentage / 100);
        let juros = p.has_interest ? ` (c/juros de ${vJuros}% a.m)` : "";
        return `${p.installment}x de R$ ${p.currency}${juros}`;
      },
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.p-inputtext) {
  font-size: 1.3em;
}
</style>