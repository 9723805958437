<template>
  <form @submit.prevent="salvar">
    <div class="p-grid card">
      <div class="p-col-12">
        <h5>Senha Financeira</h5>
      </div>
      <template v-if="!$store.state.user?.temPwdFinanceira">
        <div class="p-col-12">
          <div
            class="card p-p-3 p-text-center"
            style="background-color: #f9c851; font-weight: bold"
          >
            Por segurança, a sua primeira senha financeira será gerada
            automaticamente pelo nosso sistema.<br /><br />
            Para gerar agora e receber a sua senha financeira no e-mail
            <a @click.prevent="gerarSenha" href="#">clique aqui</a>
            <p-progress-spinner
              v-if="gerando"
              style="width: 15px; height: 15px"
            />
          </div>
        </div>
      </template>

      <div class="p-col-12 p-md-6 p-md-offset-3" v-if="mostrarForm">
        <div class="p-fluid">
          <div class="p-field">
            <label>E-mail</label>
            <p-inputtext
              :value="$store.state.user?.email"
              required
              autocomplete="username"
              disabled
            />
          </div>
          <div class="p-field">
            <label>
              Senha financeira atual
              <span
                style="float: right"
                v-if="$store.state.user?.temPwdFinanceira"
              >
                <a href="#" @click.prevent="esqueceuSenha"
                  >Esqueci minha senha</a
                >
                <p-progress-spinner
                  v-if="gerando"
                  style="width: 15px; height: 15px"
                />
              </span>
            </label>
            <input
              type="hidden"
              :value="$store.state.user?.email"
              autocomplete="username"
            />
            <p-inputtext
              type="password"
              v-model="input.password"
              required
              autocomplete="current-password"
            />
          </div>
          <div class="p-field">
            <label>Nova senha financeira</label>
            <p-inputtext
              type="password"
              v-model="input.newPassword"
              required
              autocomplete="new-password"
            />
          </div>
          <div class="p-field">
            <label>Repita a nova senha financeira</label>
            <p-inputtext
              type="password"
              v-model="input.newPasswordConfirmation"
              required
              autocomplete="new-password"
            />
          </div>
        </div>
      </div>
      <div
        class="p-d-flex p-flex-row-reverse p-col-12 p-md-6 p-md-offset-3"
        style="justify-content: end"
      >
        <p-button
          v-if="mostrarForm"
          :icon="saving ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          label="Alterar"
          type="submit"
          :disabled="saving"
        />
        <p-button
          class="p-button-secondary p-mr-2"
          label="Cancelar"
          @click="$router.push({ name: 'dashboard' })"
        />
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { UserChangePassword, getService } from "@/services/UserService";
import { computed, defineComponent, onMounted, ref } from "vue";
import Avatar from "@/components/Avatar.vue";
import { http } from "@/api/rest";
import { useToast } from "primevue/usetoast";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/actions";
import { useConfirm } from "primevue/useconfirm";

export function useSenhaFinanceira() {
  const toast = useToast();
  const confirm = useConfirm();

  const gerando = ref(false);
  const gerado = ref(false);

  async function pwdFinanceira(msg = "Senha enviada no e-mail") {
    gerando.value = true;
    try {
      await http.get("api/users/pwd-financeira");
      toast.add({
        severity: "success",
        detail: msg,
        life: 3000,
      });
      gerado.value = true;
    } finally {
      gerando.value = false;
    }
  }

  return {
    gerado,
    gerando,
    pwdFinanceira,
    esqueceuSenha() {
      confirm.require({
        message:
          "Se você esqueceu sua senha financeira podemos gerar e enviar uma nova senha financeira para o seu e-mail.\n\nDeseja continuar?",
        header: "Confirmação",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Enviar Uma Nova Senha",
        rejectLabel: "Cancelar",
        async accept() {
          pwdFinanceira(
            "Enviamos uma nova senha financeira aleatória para o seu e-mail. Favor verificar"
          );
        },
      });
    },
  };
}

export default defineComponent({
  components: {
    Avatar,
  },
  setup() {
    const store = useStore();
    const saving = ref(false);

    const { gerando, gerado, pwdFinanceira, esqueceuSenha } =
      useSenhaFinanceira();

    const input = ref<UserChangePassword>({
      password: "",
      newPassword: "",
      newPasswordConfirmation: "",
    });

    const mostrarForm = computed(() => {
      return gerado.value || store.state.user?.temPwdFinanceira;
    });

    onMounted(async () => {
      await store.dispatch(ActionTypes.LOAD_ME);
    });

    return {
      saving,
      gerando,
      gerado,
      input,
      mostrarForm,
      gerarSenha() {
        pwdFinanceira();
      },
      esqueceuSenha,
    };
  },
  methods: {
    async salvar() {
      if (this.input.newPassword !== this.input.newPasswordConfirmation) {
        this.$toast.add({
          severity: "warn",
          summary: "A confirmação de senha não está correta",
          life: 3000,
        });
        return;
      }
      this.saving = true;
      try {
        await getService().changePasswordFinanceira(this.input);
        this.$toast.add({
          severity: "success",
          detail: "Senha alterada com sucesso",
          life: 3000,
        });
        // this.$router.push({ name: "dashboard" });
        this.input = {
          password: "",
          newPassword: "",
          newPasswordConfirmation: "",
        };
        if (!this.$store.state.user?.temPwdFinanceira) {
          await this.$store.dispatch(ActionTypes.LOAD_ME);
        }
      } finally {
        this.saving = false;
      }
    },
  },
});
</script>
