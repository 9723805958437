
import { client } from "@/api/apollo";
import { Pedido } from "@/services/PedidoService";
import { TipoPagamento } from "@/services/TipoPagamentoService";
import { clone } from "@/utils/object";
import gql from "graphql-tag";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, nextTick, PropType, ref, watch } from "vue";
import { detectCardType } from "@/utils/card";
import { getInstallments, getPaymentToken } from "@/gerencianet";
import { formatNumber } from "@/utils/format";
import { useStore } from "@/store";

interface Cartao {
  brand: string; // 'visa', // bandeira do cartão
  number: string; //'4012001038443335', // número do cartão
  cvv: string; //'123', // código de segurança
  expiration_month: string; //'05', // mês de vencimento
  expiration_year: string; //'2021' // ano de ve
}

interface Installment {
  currency: string; // "75,00"
  has_interest: boolean;
  installment: 1;
  interest_percentage: number; // 0
  value: number;
}

const initialCartao: Cartao = {
  brand: "",
  number: "",
  cvv: "",
  expiration_month: "",
  expiration_year: "",
};

interface PedidoPagamentoResponse {
  type: "sucess" | "error";
  mensagem: string;
}

async function pagar(
  pedidoId: number,
  tipoPagamentoId: number,
  paymentToken: string,
  cardInfo: any
): Promise<PedidoPagamentoResponse> {
  const result = await client.query({
    variables: {
      pedidoId,
      tipoPagamentoId,
      paymentToken,
      cardInfo: JSON.stringify(cardInfo),
    },
    query: gql`
      query (
        $pedidoId: ID!
        $tipoPagamentoId: ID!
        $paymentToken: String
        $cardInfo: Json
      ) {
        pagarPedido(
          pedidoId: $pedidoId
          tipoPagamentoId: $tipoPagamentoId
          paymentToken: $paymentToken
          cardInfo: $cardInfo
        ) {
          type
          mensagem
        }
      }
    `,
  });
  const {
    data: { pagarPedido },
  } = result;
  return pagarPedido;
}

async function requestPaymentToken(card: Cartao) {
  const {
    data: { payment_token },
  } = await getPaymentToken(card);
  return payment_token as string;
}

export default defineComponent({
  emits: ["update:visible", "pagou"],
  props: {
    pedido: {
      type: Object as PropType<Pedido>,
      required: true,
    },
    tipoPagamento: {
      type: Object as PropType<TipoPagamento>,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isTypingCvc = ref(false);
    const exp = ref("");
    const nome = ref("");
    const documento = ref("");
    const saving = ref(false);
    const card = ref<Cartao>(clone(initialCartao));
    const telefone = ref("");
    const dataNascimento = ref("");
    const infoExtra = computed(() => {
      return (
        documento.value.replace(/\D/g, "").length >= 11 &&
        documento.value !== store.state.user?.pessoa.documento
      );
    });

    const installmentLoading = ref(false);
    const installments = ref<Installment[]>([]);
    const opcao = ref<Installment>(null!);

    const msg = ref<string>("");
    const msgKey = ref<number>(0);

    const totalPagar = computed(() => {
      return props.pedido.taxaAdesao || props.pedido.total;
    });

    watch(
      () => props.visible,
      (v) => {
        if (!v) {
          installments.value = [];
          opcao.value = null!;
          card.value = clone(initialCartao);
          exp.value = "";
        }
      }
    );

    const brand = computed(() => {
      return detectCardType(card.value.number.replace(/\D/g, "")) || "";
    });
    watch(brand, async (b) => {
      opcao.value = null!;
      installments.value = [];
      if (b) {
        installmentLoading.value = true;
        try {
          installments.value = (
            await getInstallments(totalPagar.value, brand.value)
          ).installments;
          opcao.value = installments.value[0];
        } catch (e: any) {
          console.log(e);
          msgKey.value++;
          msg.value = `Falha ao recuperar as opçoes de pagamento.${e.error_description || ""
            }`;
          return;
        } finally {
          installmentLoading.value = false;
        }
      }
    });

    const toast = useToast();

    function close() {
      if (!saving.value) {
        emit("update:visible", false);
      }
    }

    return {
      installmentLoading,
      opcao,
      installments,
      msg,
      msgKey,
      close,
      exp,
      isTypingCvc,
      nome,
      saving,
      documento,
      card,
      telefone,
      dataNascimento,
      infoExtra,
      async pagar() {
        msg.value = "";
        saving.value = true;
        try {
          const cc = clone(card.value);
          cc.number = cc.number.replace(/\D/g, "");
          cc.brand = detectCardType(cc.number) || "";
          const [m, y] = exp.value.split("/");
          cc.expiration_month = m;
          cc.expiration_year = `20${y}`;

          let paymentToken = "";
          try {
            paymentToken = await requestPaymentToken(cc);
          } catch (e) {
            console.log(e);
            msgKey.value++;
            msg.value = "Cartão inválido";
            return;
          }

          const resp = await pagar(
            props.pedido.id,
            props.tipoPagamento.id,
            paymentToken,
            {
              nome: nome.value,
              documento: documento.value,
              telefone: telefone.value,
              dataNascimento: dataNascimento.value,
              installment: clone(opcao.value),
            }
          );
          if (resp.type === "error") {
            msgKey.value++;
            msg.value = resp.mensagem;
            return;
          }
          emit("pagou");
        } catch (e) {
          console.log(e);
        } finally {
          saving.value = false;
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && saving.value) {
          return;
        }
        emit("update:visible", v);
      },

      formatInstallment(p: Installment) {
        if (!p) {
          return;
        }
        if (p.installment === 1) {
          return `1x de R$ ${p.currency} (sem juros)`;
        }
        let vJuros = formatNumber(p.interest_percentage / 100);
        let juros = p.has_interest ? ` (c/juros de ${vJuros}% a.m)` : "";
        return `${p.installment}x de R$ ${p.currency}${juros}`;
      },
    };
  },
});
