// @ts-nocheck

export function installGerenciaNet() {
  const url = (process.env.NODE_ENV === "production" &&
      process.env.VUE_APP_SANDBOX !== "true")
    ? "https://api.gerencianet.com.br/v1/cdn/"
    : "https://sandbox.gerencianet.com.br/v1/cdn/";

  const integrationId = "12d6d9e0eab505b8611e12e086c6643f";

  const s = document.createElement("script");
  s.type = "text/javascript";
  s.async = false;
  s.id = integrationId;
  const v = parseInt(Math.random() * 1000000);
  s.src = `${url}${integrationId}/${v}`;

  if (!document.getElementById(integrationId)) {
    document.getElementsByTagName("head")[0].appendChild(s);
  }
  globalThis.$gn = {
    validForm: true,
    processed: false,
    done: {},
    ready: function (fn) {
      globalThis.$gn.done = fn;
    },
  };
}

export async function getGnCheckout() {
  return new Promise((resolve, reject) => {
    if (globalThis.$gnCheckout) {
      resolve(globalThis.$gnCheckout);
      return;
    }
    installGerenciaNet();
    globalThis.$gn.ready(function (checkout) {
      globalThis.$gnCheckout = checkout;
      resolve(checkout);
    });
  });
}

export async function getPaymentToken(card): any {
  const checkout = await getGnCheckout();
  return new Promise((resolve, reject) => {
    checkout.getPaymentToken(card, function (error, response) {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
}

export async function getInstallments(total: number, brand: string): any {
  const checkout = await getGnCheckout();
  return new Promise((resolve, reject) => {
    const tot = Math.trunc(total * 100);
    checkout.getInstallments(tot, brand, function (error, response) {
      if (error) {
        reject(error);
      } else {
        resolve(response.data);
      }
    });
  });
}
