<template>
  <div class="p-grid card">
    <h5
      class="p-d-flex p-flex-column p-flex-sm-row p-ai-center"
      style="width: 100%"
    >
      <span style="flex: 1; width: 100%; min-width: 200px">
        Pedido Efetuado
        <p-progress-spinner v-if="loading" style="width: 15px; height: 15px" />
      </span>
      <div class="p-text-right" style="width: 100%">
        <p-button
          class="p-button-secondary p-mr-2"
          label="Voltar"
          @click="voltar"
        />
      </div>
    </h5>

    <div v-if="pedido" class="p-col-12">
      <p-message severity="success" :closable="false">
        Pedido <b>{{ pedido.id }}</b> realizado com sucesso !!
      </p-message>

      <template v-if="pedido.taxaAdesao">
        <h5>
          Valor total do pedido: <b>{{ $formatCurrency(pedido.total) }}</b>
        </h5>
        <h5>
          Valor à pagar diretamente ao Ponto de Apoio:
          <b>{{ $formatCurrency(pedido.total - pedido.taxaAdesao) }}</b>
        </h5>
        <h5>
          Valor à pagar para a empresa e ativar o seu plano
          {{ pedido.perfil?.nome }}:
          <b>{{ $formatCurrency(pedido.taxaAdesao) }}</b>
        </h5>
        <h5 style="color: red">
          Escolha uma das opções abaixo para pagar a taxa de ativação do seu
          plano:
        </h5>
      </template>
      <h4 v-else>Total à pagar: {{ $formatCurrency(pedido.total) }}</h4>

      <p-message
        severity="success"
        :closable="false"
        v-if="pedido.status == 'solicitado_pa'"
      >
        Foi enviado para o Ponto de Apoio
      </p-message>

      <p-message
        severity="success"
        :closable="false"
        v-if="pedido.status == 'pago'"
      >
        Pagamento concluído !!!
      </p-message>

      <p-message
        severity="success"
        :closable="false"
        v-if="pedido.status == 'pagto_pendente'"
      >
        Aguarde o pagamento está sendo processado !!
      </p-message>

      <div v-if="pedido.status === 'pendente'">
        <p-card>
          <template #title> Forma de Pagamento </template>
          <template #content>
            <p-progress-spinner v-if="loadingTiposPagamentos" />
            <template v-else>
              <div v-if="tiposPagamentos" class="p-grid">
                <div v-for="(t, i) in tiposPagamentos" :key="i">
                  <div
                    v-if="t.tipo === 'saldo' && canPagSaldo"
                    class="card-tipo-pagamento"
                  >
                    <img
                      src="/img/pag_saldo.jpeg"
                      style="width: 200px; cursor: pointer"
                      @click="dialogSaldo = true"
                    />
                    <PagSaldo
                      :pedido="pedido"
                      :tipoPagamento="t"
                      v-model:visible="dialogSaldo"
                      @pagou="pedidoPago"
                    />
                  </div>
                  <div v-if="t.tipo === 'pix'" class="card-tipo-pagamento">
                    <img
                      src="/img/pix_branco.jpeg"
                      style="width: 200px; cursor: pointer"
                      @click="dialogPix = true"
                    />
                    <Pix
                      :pedido="pedido"
                      :tipoPagamento="t"
                      v-model:visible="dialogPix"
                      @pagou="pedidoPago"
                    />
                  </div>
                  <div
                    v-if="
                      t.tipo === 'credit_card' &&
                      !$store.state.user?.temPontoApoio
                    "
                    class="card-tipo-pagamento"
                  >
                    <img
                      src="/img/cartao_de_credito.jpeg"
                      style="width: 200px; cursor: pointer"
                      @click="dialogCard = true"
                    />
                    <PagCard
                      :pedido="pedido"
                      :tipoPagamento="t"
                      v-model:visible="dialogCard"
                      @pagou="pedidoPago('pagto_pendente')"
                    />
                  </div>
                </div>
              </div>
              <div v-else>Nenhum tipo de pagamento configurado</div>
            </template>
          </template>
        </p-card>
      </div>

      <h5 style="color: red" v-if="pedido.taxaAdesao && isCompraFromPa">
        O seu pedido será enviado ao Ponto de Apoio após o pagamento da taxa
        acima.
      </h5>
    </div>
  </div>
</template>

<script lang="ts">
import {
  getInstance as getPedidoService,
  Pedido,
} from "@/services/PedidoService";
import {
  TipoPagamento,
  getTiposPagamentos,
} from "@/services/TipoPagamentoService";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Pix from "./Pix.vue";
import PagSaldo from "./PagSaldo.vue";
import PagCard from "./PagCartao.vue";

export default defineComponent({
  components: { Pix, PagCard, PagSaldo },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const tiposPagamentos = ref<TipoPagamento[]>([]);
    const loadingTiposPagamentos = ref(false);
    async function loadTiposPagamentos() {
      loadingTiposPagamentos.value = true;
      try {
        tiposPagamentos.value = await getTiposPagamentos();
      } finally {
        loadingTiposPagamentos.value = false;
      }
    }

    const pedido = ref<Pedido>();
    const loading = ref(false);
    async function loadPedido() {
      loading.value = true;
      try {
        pedido.value = await getPedidoService().getById(
          route.params.id as unknown as number
        );
        if (pedido.value.status === "pendente") {
          loadTiposPagamentos();
        }
      } finally {
        loading.value = false;
      }
    }
    onMounted(loadPedido);

    const dialogPix = ref(false);
    const dialogCard = ref(false);
    const dialogSaldo = ref(false);

    function pedidoPago(status = "pago") {
      dialogPix.value = false;
      dialogCard.value = false;
      dialogSaldo.value = false;
      pedido.value && (pedido.value.status = status);
    }

    const canPagSaldo = computed(
      () => pedido.value?.tipo !== "primeiro_pedido"
    );
    const isCompraFromPa = computed(
      () =>
        pedido.value?.pontoApoio &&
        !["pa", "pa_ativacao"].includes(pedido.value?.tipo || "")
    );

    return {
      loading,
      pedido,
      voltar() {
        router.push({ name: "loja" });
      },

      loadingTiposPagamentos,
      tiposPagamentos,
      dialogPix,
      dialogCard,
      dialogSaldo,
      pedidoPago,
      canPagSaldo,
      isCompraFromPa,
    };
  },
});
</script>

<style scoped lang="scss">
.card-tipo-pagamento {
  border: 5px solid #e9ecef;
  padding: 5px;
  border-radius: 20px;
  margin: 0.5em;
  cursor: pointer;
  position: relative;
}
</style>