
import { UserChangePassword, getService } from "@/services/UserService";
import { computed, defineComponent, onMounted, ref } from "vue";
import Avatar from "@/components/Avatar.vue";
import { http } from "@/api/rest";
import { useToast } from "primevue/usetoast";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/actions";
import { useConfirm } from "primevue/useconfirm";

export function useSenhaFinanceira() {
  const toast = useToast();
  const confirm = useConfirm();

  const gerando = ref(false);
  const gerado = ref(false);

  async function pwdFinanceira(msg = "Senha enviada no e-mail") {
    gerando.value = true;
    try {
      await http.get("api/users/pwd-financeira");
      toast.add({
        severity: "success",
        detail: msg,
        life: 3000,
      });
      gerado.value = true;
    } finally {
      gerando.value = false;
    }
  }

  return {
    gerado,
    gerando,
    pwdFinanceira,
    esqueceuSenha() {
      confirm.require({
        message:
          "Se você esqueceu sua senha financeira podemos gerar e enviar uma nova senha financeira para o seu e-mail.\n\nDeseja continuar?",
        header: "Confirmação",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Enviar Uma Nova Senha",
        rejectLabel: "Cancelar",
        async accept() {
          pwdFinanceira(
            "Enviamos uma nova senha financeira aleatória para o seu e-mail. Favor verificar"
          );
        },
      });
    },
  };
}

export default defineComponent({
  components: {
    Avatar,
  },
  setup() {
    const store = useStore();
    const saving = ref(false);

    const { gerando, gerado, pwdFinanceira, esqueceuSenha } =
      useSenhaFinanceira();

    const input = ref<UserChangePassword>({
      password: "",
      newPassword: "",
      newPasswordConfirmation: "",
    });

    const mostrarForm = computed(() => {
      return gerado.value || store.state.user?.temPwdFinanceira;
    });

    onMounted(async () => {
      await store.dispatch(ActionTypes.LOAD_ME);
    });

    return {
      saving,
      gerando,
      gerado,
      input,
      mostrarForm,
      gerarSenha() {
        pwdFinanceira();
      },
      esqueceuSenha,
    };
  },
  methods: {
    async salvar() {
      if (this.input.newPassword !== this.input.newPasswordConfirmation) {
        this.$toast.add({
          severity: "warn",
          summary: "A confirmação de senha não está correta",
          life: 3000,
        });
        return;
      }
      this.saving = true;
      try {
        await getService().changePasswordFinanceira(this.input);
        this.$toast.add({
          severity: "success",
          detail: "Senha alterada com sucesso",
          life: 3000,
        });
        // this.$router.push({ name: "dashboard" });
        this.input = {
          password: "",
          newPassword: "",
          newPasswordConfirmation: "",
        };
        if (!this.$store.state.user?.temPwdFinanceira) {
          await this.$store.dispatch(ActionTypes.LOAD_ME);
        }
      } finally {
        this.saving = false;
      }
    },
  },
});
